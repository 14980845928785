<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      this_is_final: {},
      combinations: [],
      attributeData: [],
      attributeValues: [],
      uploadFile: true,
      isEdit: false,
      attribute_value: null,
      attribute_id: null,
      associatedvarients: null,
      id: null,
      selectedAttributeId: null,
      productAttributeData: [],
    };
  },

  mounted() {
    this.getAttributes();
  },

  methods: {
    uploadImage(event) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      let FILE = event.target.files[0];
      let formData = new FormData();
      formData.append("csv_files", FILE, FILE.name);
      formData.append("product_id", this.id);
      this.$store.getters.client
        .post("/variant/csv-variant/", formData)
        .then(() => {
          this.$emit("updatePage", 3);
          loader.hide();
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          console.log(error);
          loader.hide();
        });
    },
    getvariant() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .post(`variant/get-variant/`, { product_id: this.id })
        .then((response) => {
          this.this_is_final = response.data.data;
          this.productAttributeData = this.this_is_final.AttributeValueSorted;
          this.productAttributeData = this.productAttributeData.map((x) => {
            return {
              ...x,
              all_attribute_values: x.attribute_values,
              sequence: 0,
            };
          });
          loader.hide();
        })
        .catch((error) => {
          console.log(error);
          loader.hide();
        });
    },
    setAssociatedAttribute() {
      const result =
        this.this_is_final.associated_attribute.product_type_values.reduce(
          (
            acc,
            { attribute_id, attribute_value, attribute, all_attribute_values }
          ) => {
            acc[attribute_id] ??= {
              id: attribute,
              attribute_value: [],
              all_attribute_val: all_attribute_values,
            };
            if (Array.isArray(attribute_value))
              // if it's array type then concat
              acc[attribute_id].value =
                acc[attribute_id].value.concat(attribute_value);
            else acc[attribute_id].attribute_value.push(attribute_value);
            // acc[all_attribute_val] = all_attribute_val
            return acc;
          },
          {}
        );

      this.productAttributeData = Object.values(result);
      this.associatedvarients = Object.values(result);
    },
    generateCombination() {
      this.associatedvarients = this.productAttributeData;
      const result = [];
      const [first, ...rest] = this.productAttributeData.map(
        (obj) => obj.attribute_value
      );
      const product = rest.reduce(
        (acc, arr) => acc.flatMap((x) => arr.map((y) => [...x, y])),
        first.map((x) => [x])
      );
      for (let i = 0; i < product.length; i++) {
        const combination = [];
        for (let j = 0; j < this.productAttributeData.length; j++) {
          const obj = this.productAttributeData[j];
          const option = obj.id;
          const value = product[i][j];
          combination.push({ option, value });
        }
        result.push({
          option_values: combination,
          prices: [{ quantity: 1, express: 0, standard: 0, value: 0 }],
        });
      }
      this.this_is_final.variants = result;
    },

    addField() {
      this.productAttributeData.push({
        attribute_value: [],
        id: null,
        all_attribute_val: [],
      });
    },

    addQuentityRow(item) {
      item.prices.push({ quantity: 1, express: 0, standard: 0, value: 0 });
    },
    removeQuentityRow(item, val) {
      val.prices.splice(item, 1);
    },
    selectedAttribute(att, index) {
      this.selectedAttributeId = att.id;
      this.getAttributes(index);
    },

    selectedAttributeValue(att, index) {
      this.productAttributeData[index].attribute_value.push(att.id);
    },

    deleteField(index) {
      this.productAttributeData.splice(index, 1);
    },

    async getAttributes(index) {
      let loader = this.$loading.show({
        loader: "dots",
      });

      if (this.selectedAttributeId === null) {
        await this.$store.getters.client
          .get(`/attributes/`)
          .then((response) => {
            this.attributeData = response.data.results;
            loader.hide();
          });
      } else {
        this.attribute_value = null;
        this.attributeValues = [];
        this.$store.getters.client
          .get(`/attributes/${this.selectedAttributeId}`)
          .then((response) => {
            this.productAttributeData[index].all_attribute_val =
              response.data.attribute_values;
            loader.hide();
          });
      }
    },

    checkNumericInput(detail, field) {
      const value = parseFloat(detail[field]);
      if (isNaN(value)) {
        // Set the respective 'isInvalid' property to true if value is NaN
        detail[
          "isInvalid" + field.charAt(0).toUpperCase() + field.slice(1)
        ] = true;
      } else {
        // Set the respective 'isInvalid' property to false if value is valid
        detail[
          "isInvalid" + field.charAt(0).toUpperCase() + field.slice(1)
        ] = false;
      }
    },

    submitVarient() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      const data = {};
      const newVariants = this.this_is_final.variants.map((variant) => {
        const newOptionValues = variant.option_values.map((optionValue) => {
          return {
            option_id: optionValue.option.id,
            value_id: optionValue.value.id,
          };
        });

        return {
          prices: variant.prices,
          option_values: newOptionValues,
        };
      });

      const product_attribute_data_id = [];
      for (
        let index = 0;
        index < this.associatedvarients.length &&
        this.associatedvarients.length > 0;
        index++
      ) {
        const element = this.associatedvarients[index];
        let ids = element.attribute_value.map((item) => item.id);
        product_attribute_data_id.push({
          attribute_id: element.id.id,
          attribute_value_id: ids,
        });
      }
      data.variants = newVariants;
      data.product_id = this.id;
      data.associated_attribute = product_attribute_data_id;
      this.$store.getters.client
        .post(`variant/create-variant/`, data)
        .then(() => {
          loader.hide();
          this.$emit("updatePage", 3);
        })
        .catch((error) => {
          console.log(error);
          loader.hide();
        });
    },
  },

  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
    }
  },
};
</script>

<template>
  <div class="row">
    <b-col cols="12">
      <b-button variant="warning" class="w-100 mb-3" @click="uploadFile = !uploadFile">
        <span v-if="uploadFile" @click="getvariant()">Upload Manually</span>
        <span v-else>Upload file</span>
      </b-button>
    </b-col>
    <b-col cols="12" v-show="uploadFile">
      <b-card title="Upload Price List">
        <div class="border-img">
          <input type="file" v-on:change="uploadImage($event)" />
        </div>
      </b-card>
    </b-col>
    <div class="col-lg-12" v-show="!uploadFile">
      <div class="card">
        <div class="card-body">
          <div class="add-button mx-0">
            <button v-on:click="addField()" class="btn btn-primary btn-sm" type="button">
              + Add Attribute
            </button>
          </div>
          <div class="form-group" v-if="productAttributeData.length > 0">
            <div class="row" v-for="(item, index) of productAttributeData" :key="index">
              <div class="col">
                <label>Attribute</label>
                <b-form-input v-model="item.attribute_name" disabled></b-form-input>

                <multiselect name="category_id" :value.sync="item.id" label="name" track-by="id" v-model="item.id"
                  @select="selectedAttribute($event, index)" :options="attributeData"></multiselect>
              </div>

              <div class="col">
                <label>Attribute Value</label>

                <multiselect v-validate="'required'" name="category_value" :value.sync="item.attribute_values"
                  :multiple="true" :options="item.all_attribute_values" label="name" v-model="item.attribute_values"
                  track-by="id" :hide-selected="true" :searchable="true"></multiselect>
              </div>

              <div class="col-3">
                <label>Delete</label>

                <div class="text-danger">
                  <i v-on:click="
                    deleteField(index, item);
                  generateCombination();
                  " class="mdi mdi-trash-can font-size-18"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-card title="Varients">
        <b-row class="mb-3">
          <div class="col-5">Combination</div>
          <div class="col">Quentity</div>
          <div class="col">Express</div>
          <div class="col">Standard</div>
          <div class="col">Value</div>
          <div class="col"></div>
        </b-row>
        <b-row class="mb-3" v-for="(combination, index) in this_is_final.variants" :key="index">
          <b-col cols="5">
            <b-badge class="mr-2" variant="light" v-for="(val, j) in combination.option_values" :key="j">{{
              val.value.name }}</b-badge>
          </b-col>
          <b-col cols="7">
            <b-row class="mb-2" v-for="(detail, i) in combination.prices" :key="i">
              <div class="col">
                <b-form-input v-model="detail.quantity" size="sm" type="number" class="small-input"
                  @input="checkNumericInput(detail, 'quantity')"
                  :class="{ 'invalid-input': detail.isInvalidQuantity }"></b-form-input>
              </div>
              <div class="col">
                <b-form-input type="number" class="small-input" v-model="detail.express" size="sm"
                  @input="checkNumericInput(detail, 'express')"
                  :class="{ 'invalid-input': detail.isInvalidExpress }"></b-form-input>
              </div>
              <div class="col">
                <b-form-input v-model="detail.standard" size="sm" type="number" class="small-input"
                  @input="checkNumericInput(detail, 'standard')"
                  :class="{ 'invalid-input': detail.isInvalidStandard }"></b-form-input>
              </div>
              <div class="col">
                <b-form-input type="number" class="small-input" v-model="detail.value" size="sm"
                  @input="checkNumericInput(detail, 'value')"
                  :class="{ 'invalid-input': detail.isInvalidValue }"></b-form-input>
              </div>
              <div class="col">
                <b-button variant="success" size="sm" v-if="i === combination.prices.length - 1"
                  @click="addQuentityRow(combination)">+</b-button>
                <b-button variant="danger" size="sm" v-else @click="removeQuentityRow(i, combination)">-</b-button>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div class="d-flex justify-content-between">
      <button @click="$emit('previousPage', 1)" type="button" class="btn btn-danger float-right">
        Back
      </button>
      <button @click="$emit('updatePage', 3)" type="button" class="btn btn-primary float-right">
        Next
      </button>
    </div>
  </div>
</template>

<style>
.invalid-input {
  border-color: red !important;
}

.small-input {
  -moz-appearance: textfield;
}

.small-input::-webkit-inner-spin-button {
  display: none;
}

.small-input::-webkit-outer-spin-button,
.small-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>